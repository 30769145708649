module.exports = [{
      plugin: require('C:/Users/Supervisor/site/quintadaspoesias/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-139042200-1"},
    },{
      plugin: require('C:/Users/Supervisor/site/quintadaspoesias/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/Supervisor/site/quintadaspoesias/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
